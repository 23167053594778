<template>
  <div class="vipOrder">
    <div class="title"> 会员订单</div>
    <div class="content">
      <div class="list_box" v-if="list_data.lists.length">
        <div class="vip_order_item" v-for="(item,index) in list_data.lists" :key="index">
          <div class="order_top_box">
            <div class="order_sn">
              订单编号：{{ item.order_sn }}
            </div>
            <div class="order_status">
              <span v-if="item.pay_status == 1">已支付</span>
              <span v-else>未支付</span>
            </div>
          </div>
          <div class="order_content">
            <div class="order_name">
              {{ item.name }}
            </div>
            <div class="row_item_box">
              <div class="row_title_box">
                时长:
              </div>
              <div class="row_content_box">
                {{item.month}}个月
              </div>
            </div>
            <div class="row_item_box">
              <div class="row_title_box">
                支付时间:
              </div>
              <div class="row_content_box">
                {{ item.pay_time }}
              </div>
            </div>
            <div class="row_item_box">
              <div class="row_title_box">
                支付金额:
              </div>
              <div class="row_content_box">
                ￥{{ item.price }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>

  </div>
</template>

<script>
import mixin from '../../../mixin';

export default {
  mixins: [mixin],

  data () {
    return {

    }
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      this.getList('getOrderList', this.page);
    },
  }
}
</script>

<style lang="scss" scoped>
.vipOrder {
  padding: 21px 17px 30px;
  background: #fff;
  border-radius: 8px 8px 8px 8px;
  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  .content {
    margin: 10px 5px 5px;
    height: 650px;

    .list_box {
      .vip_order_item {
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #e5e5e5;

        padding: 17px 13px;
        box-shadow: 0px 0px 5px 0px #e4e9ff;
        margin-bottom: 20px;
        .order_top_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #e5e5e5;
          padding-bottom: 9px;
          .order_sn {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
          }
          .order_status {
            font-size: 14px;
            font-weight: 400;
            color: #3f63ff;
          }
        }
        .order_content {
          padding: 15px 7px 0;
          .order_name {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 12px;
          }
          .row_item_box {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 8px;
            .row_title_box {
              margin-right: 5px;
            }
            .row_content_box {
            }
          }
        }
      }
    }
  }
}
</style>